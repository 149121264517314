<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseFormV2.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {   
      title:'Kepemilikan',
      dataurl:'/ownership',
      baseroute:'kepemilikan',
      fields:[
        { key: 'name', label: 'Nama', type: 'input', rules:'required' , placeholder:'Nama'},
      ]
    }
  },
}
</script>